<template>
    <dashboard-layout>
        <v-row v-if="user && user.patient">
            <v-col cols="12">
                <v-card class="mx-auto my-auto" rounded="lg">
                    <v-card-text>
                        <v-row>
                            <v-col cols="auto">
                                <v-row>
                                    <v-col
                                        cols="12"
                                        class="d-flex justify-center"
                                    >
                                        <v-avatar size="150">
                                            <img
                                                :src="user.avatar_url"
                                                alt="avatar"
                                            />
                                        </v-avatar>
                                    </v-col>
                                    <v-col>
                                        <CustomFileUpload
                                            label="Загрузить фото"
                                            v-model="user.avatar_url"
                                            @change="handleUpdateProfile"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-form>
                                    <v-row>
                                        <v-col cols="12">
                                            <CustomInput
                                                v-model="user.name"
                                                label="Ваше имя"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <CustomInput
                                                v-model="user.email"
                                                label="Ваше email"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-slider
                                                v-model="user.patient.goal"
                                                label="Цель"
                                                max="24"
                                                min="0"
                                                thumb-label="always"
                                                color="secondary"
                                                ></v-slider>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card rounded="lg">
                    <v-card-title> Сменить пароль</v-card-title>
                    <v-card-text>
                        <v-row dense>
                            <v-col cols="12"
                                ><CustomInput label="Текущий пароль"
                            /></v-col>
                            <v-col cols="12"
                                ><CustomInput label="Новый пароль"
                            /></v-col>
                            <v-col cols="12"
                                ><CustomInput label="Повторите пароль"
                            /></v-col>
                            <CustomButton style="width: 100%"
                                >Сохранить</CustomButton
                            >
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card rounded="lg">
                    <v-card-title> Подписка</v-card-title>
                    <v-card-text>
                        <v-row dense>
                            <v-col cols="12"></v-col>
                            <v-col cols="12">
                                <div>
                                    <span class="text-body-1"
                                        ><b>Basic </b> до 14.10.2021</span
                                    >
                                </div>
                                <div>Следующее списание 13.10.2021</div>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center"
                                >Подробнее о подписке</v-col
                            >
                            <CustomButton style="width: 100%"
                                >Расширить подписку</CustomButton
                            >
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import DashboardLayout from "../../layouts/dashboard/Index";
import User from "../../models/User";
export default {
    name: "Profile",
    components: { DashboardLayout },
    data: function () {
        return {
            user: {},
        };
    },
    methods: {
        async handleUpdateProfile() {
            const user = new User(this.user);
            this.user = await user.save();
        },
    },
    mounted() {
        this.user = this.$auth.user();
    },
};
</script>

<style scoped>
</style>
